import Sdk from 'casdoor-js-sdk'
import axios from 'axios'
import type { UserPlugin } from '~/types'

let CasdoorSdk: any = null

export const initCasdoorSdk = (config: any) => {
  CasdoorSdk = new Sdk(config)
}
export const signin = () => {
  return CasdoorSdk.signin(CasdoorSdk.config.serverUrl)
}

export const getSignupUrl = () => {
  return CasdoorSdk.getSignupUrl()
}

export const GetOAuthToken = (code: any, state: any) => {
  return CasdoorSdk.signin(CasdoorSdk.config.serverUrl, { code, state })
}

export const getSigninUrlAuth = () => {
  const redirectUri = `${window.location.origin}${CasdoorSdk.config.redirectPath}`
  const scope = 'read'
  return `${CasdoorSdk.config.serverUrl.trim()}/login/oauth/authorize?client_id=${
    CasdoorSdk.config.clientId
  }&response_type=code&redirect_uri=${encodeURIComponent(
    redirectUri,
  )}&scope=${scope}&state=casdoor`
}
export const getSigninUrl = () => {
  return CasdoorSdk.getSigninUrl()
}

export const getUserProfileUrl = (userName: any, account: any) => {
  return CasdoorSdk.getUserProfileUrl(userName, account)
}

export const getMyProfileUrl = (account: any) => {
  return CasdoorSdk.getMyProfileUrl(account)
}

export const getMyResourcesUrl = (account: any) => {
  return CasdoorSdk.getMyProfileUrl(account).replace(
    '/account?',
    '/resources?',
  )
}

export const goToLink = (link: any) => {
  window.location.href = link
}

export const getAccessToken = async (data: any) => {
  const res = await fetch(
    `${import.meta.env.VITE_CASDOOR_SERVERURL}/api/login/oauth/access_token`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    },
  )
  return res.json()
}

export const getAccount = (token: any) => {
  return axios.get(
    `${
      import.meta.env.VITE_CASDOOR_SERVERURL
    }/api/get-account?accessToken=${token}`,
  )
}

export const install: UserPlugin = () => {
  // cashdoor config
  const config = {
    serverUrl: import.meta.env.VITE_CASDOOR_SERVERURL,
    clientId: import.meta.env.VITE_CASDOOR_CLIENT_ID,
    organizationName: 'casbin',
    appName: 'casdoor',
    redirectPath: '/callback',
  }

  initCasdoorSdk(config)
}
