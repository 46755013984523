<script setup lang="ts">
import { computed, toRefs } from 'vue'
import type { BreadcrumbItemProps } from '@wartek-id/belajar-id-components'
import {
  Breadcrumbs,
} from '@wartek-id/belajar-id-components'

interface Props {
  title: string
  breadcrumbs?: BreadcrumbItemProps[]
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  breadcrumbs: () => [] as BreadcrumbItemProps[],
})

const { title, breadcrumbs } = toRefs(props)

const items = computed(() => [
  {
    text: 'Home',
    to: '/',
  },
  ...(breadcrumbs?.value ?? []),
])
</script>

<template>
  <Breadcrumbs :items="items" />

  <div class="mt-7 mb-8">
    <h1 class="text-[28px] font-semibold">
      {{ title }}
    </h1>
  </div>
</template>
