import VueGtag from 'vue-gtag'
import type { UserPlugin } from '~/types'
import { useAclStore, useOidcStore } from '~/pinia'

export const install: UserPlugin = ({ app, router }) => {
  const { user } = useOidcStore()
  const { roles } = useAclStore()

  app.use(VueGtag, {
    config: {
      id: import.meta.env.VITE_GA_ID,
      user_id: user?.id,
      roles,
    } as any,
  }, router)
}
