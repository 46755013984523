import { createRouter, createWebHistory } from 'vue-router'
import { setupLayouts } from 'virtual:generated-layouts'
import generatedRoutes from 'virtual:generated-pages'
import NProgress from 'nprogress'

import { handleMiddleware } from '~/middleware'
import pinia, { useAclStore, useOidcStore } from '~/pinia'
import { useGA } from '~/composables'

NProgress.configure({ showSpinner: false })

export const routes = setupLayouts(generatedRoutes)

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from) => {
  // track activity
  if (to.meta?.title) {
    const { trackActivity } = useGA()
    trackActivity({
      activity_type: 'MENU_CLICK',
      page_title: 'Belajar.id Admin',
      menu_title: to.meta.title,
      menu_url: to.path,
    })
  }

  NProgress.start()

  const oidcStore = useOidcStore(pinia())
  const aclStore = useAclStore(pinia())

  return handleMiddleware(oidcStore, aclStore, to, from)
})

router.afterEach(() => {
  NProgress.done()
})

export default router
