<script setup lang="ts">
import {
  Avatar,
  Button,
  Icon,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavDrawer,
  Navbar,
  Separator,
} from '@wartek-id/belajar-id-components'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'

import { useRoute } from 'vue-router'
import { useOidcStore } from '~/pinia'
import { filterMenu } from '~/menus'
import { ellipsis, tooltipText } from '~/utils'
import { useLayout } from '~/pinia/layout'
import { useGA } from '~/composables/useGA'

const isOpen = ref(true)
const isMobile = ref(false)
const logoutConfirmation = ref(false)
const isLogout = ref(false)

const route = useRoute()
const oidcStore = useOidcStore()
const { user } = oidcStore
const { setNavDrawerOpen, setIsMobile } = useLayout()
const menus = computed(() => filterMenu())

function onResize() {
  isMobile.value = window.innerWidth < 768
  isOpen.value = !isMobile.value
  setNavDrawerOpen(isOpen.value)
  setIsMobile(isMobile.value)
}

onResize()
onMounted(() => {
  window.addEventListener('resize', onResize)
})
onUnmounted(() => {
  window.removeEventListener('resize', onResize)
})

const handleLogout = () => {
  // isLogout.value = true
  oidcStore.signOutRedirect()
}

const handleMenuClick = () => {
  if (isMobile.value)
    isOpen.value = !isOpen.value
}

// watch(
//   () => route.fullPath,
//   (route: any) => {
//     handleMenuClick()
//   },
// )
</script>

<template>
  <div class="bg-default">
    <Navbar
      bordered
      sticky
      class="px-4 top-0 flex items-center justify-between z-10"
    >
      <div class="navbar-brand flex items-center gap-4">
        <img src="/logo.svg?url" width="40" height="40" alt="Logo">
        <span class="font-semibold leading-5">Akun<br>belajar.id</span>
      </div>

      <div>
        <Button
          prefix-icon="ic:round-logout"
          prefix-icon-class="sm:hidden"
          variant="quiet"
          @click="logoutConfirmation = !logoutConfirmation"
        >
          <span class="hidden sm:inline">Log out</span>
        </Button>

        <Button class="md:hidden w-10 shrink-0 p-0" @click="isOpen = !isOpen">
          <Icon icon="ic:menu" class="w-6 h-6" />
        </Button>
      </div>
    </Navbar>

    <div class="flex flex-col sm:flex-row">
      <NavDrawer
        v-model="isOpen"
        :fixed="isMobile"
        :overlay="isMobile"
        :sticky="!isMobile"
        shadow="lg"
        class="pt-1 px-3 w-[300px] md:top-[65px] md:h-[calc(100vh-69px)] shrink-0 shadow-xl"
      >
        <div class="md:hidden">
          <div class="navbar-brand flex items-center gap-2 py-2">
            <img src="/logo.svg?url" width="40" height="40" alt="Logo">
            <span class="font-semibold">Akun belajar.id</span>
          </div>
          <Separator class="my-1 -mx-3" />
        </div>

        <div class="flex-1 pt-2 px-1 overflow-y-auto">
          <List hovered rounded="md">
            <template v-for="(menu, index) in menus" :key="index">
              <DropdownCollapse
                v-if="menu.children"
                :items="menu.children"
                @click:navigate="handleMenuClick"
              >
                <template #action-button="{ isOpen: noCollapse, isActive, toggle }">
                  <ListItem
                    v-tooltip.right="tooltipText(menu.text)"
                    class="transition duration-200 !px-4 mb-1 hover:text-primary"
                    :class="[
                      { 'bg-gray-10 text-primary': noCollapse },
                      { '!bg-primary !text-white': isActive },
                    ]"
                    content-class="line-clamp-1 text-left"
                    as="div"
                    suffix-icon="akar-icons:chevron-down"
                    :suffix-icon-class="`transition-transform w-4 ${noCollapse ? 'transform rotate-180' : ''}`"
                    @click="toggle"
                  >
                    {{ ellipsis(menu.text) }}
                  </ListItem>
                </template>
              </DropdownCollapse>
              <ListItem
                v-else
                :ref="`item${index}`"
                v-tooltip.right="tooltipText(menu.text)"
                class="transition duration-200 !px-4 mb-1"
                exact-active-class="active bg-primary hover:!bg-primary text-white font-semibold"
                content-class="line-clamp-1 text-left"
                :to="menu.to"
              >
                {{ ellipsis(menu.text) }}
              </ListItem>
            </template>
          </List>
        </div>
        <h1 class="w-full border-b mb-4" />
        <ListItem
          v-tooltip.right="tooltipText('Profile')"
          class="transition duration-200 text-sm !px-4 flex items-center rounded-lg cursor-pointer hover:!bg-primary hover:text-white"
          exact-active-class="active bg-primary text-white font-semibold"
          content-class="line-clamp-1 text-left"
          to="/profile"
        >
          <template #prefix>
            <Avatar
              v-if="user?.profile?.picture"
              :src="user?.profile?.picture"
              size="sm"
              variant="primary"
              :name="`${user?.profile?.name}`"
            />
            <Avatar
              v-else
              variant="secondary"
              size="sm"
              :name="`${user?.profile?.name}`"
            />
          </template>
          <div class="ml-2 font-semibold truncate w-3/4">
            {{ `${user?.profile?.name}` }}
          </div>
          <template #suffix>
            <Icon icon="ic:round-keyboard-arrow-right" class="w-5 h-5" />
          </template>
        </ListItem>
      </NavDrawer>
      <main
        class="px-4 py-5 sm:px-6 sm:py-8 flex-grow w-full md:max-w-[calc(100vw-300px)] overflow-x-hidden"
      >
        <div class="xl:container xl:mx-auto">
          <RouterView />
        </div>
      </main>
      <Modal v-slot="{ close }" v-model="logoutConfirmation" width="360">
        <ModalHeader class="border-0 bg-white p-0 px-5 pt-4">
          <template #close>
            <div />
          </template>
          Keluar dari Portal Admin?
        </ModalHeader>
        <ModalBody>
          Anda akan di arahkan kembali ke halaman utama Website Akun belajar.id
        </ModalBody>
        <ModalFooter class="border-0 flex flex-col">
          <Button
            variant="primary"
            :disabled="isLogout"
            :loading="isLogout"
            @click="handleLogout"
          >
            Ya, keluar
          </Button>
          <Button :disabled="isLogout" variant="secondary" @click="close">
            Tidak
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  </div>
</template>
