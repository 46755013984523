import type { UserManagerEvents } from 'oidc-client-ts'
import { UserManager, WebStorageStateStore } from 'oidc-client-ts'
import type { DefaultOidcConfig, OidcClientSettings, OidcStoreListeners } from '~/types/oidc'

const requiredConfigProperties: Array<keyof OidcClientSettings> = [
  'authority',
  'client_id',
  'redirect_uri',
  'response_type',
  'scope',
]

const defaultOidcConfig: DefaultOidcConfig = {
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  loadUserInfo: true,
  automaticSilentSignIn: true,
}

export function getOidcConfig(oidcSettings: OidcClientSettings): OidcClientSettings {
  return Object.assign(
    defaultOidcConfig,
    oidcSettings,
    { automaticSilentRenew: false }, // automaticSilentRenew is handled in pinia and not by user manager
  )
}

export function createOidcUserManager(oidcSettings: OidcClientSettings) {
  const oidcConfig = getOidcConfig(oidcSettings)

  requiredConfigProperties.forEach((requiredProperty) => {
    if (!oidcConfig[requiredProperty]) {
      throw new Error(
        `Required oidc setting ${requiredProperty} missing for creating UserManager`,
      )
    }
  })
  return new UserManager(oidcConfig)
}

export function addUserManagerEventListener(
  oidcUserManager: UserManager,
  eventName: string,
  eventListener: OidcStoreListeners[keyof OidcStoreListeners],
) {
  const addFnName = `add${firstLetterUppercase(eventName)}`
  if (
    typeof oidcUserManager.events[addFnName as keyof UserManagerEvents] === 'function'
    && typeof eventListener === 'function'
  )
    oidcUserManager.events[addFnName as keyof UserManagerEvents](eventListener)
}

export function parseJwt(token: string) {
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64))
  }
  catch (error) {
    return {}
  }
}

export function getOidcCallbackPath(callbackUri?: string, routeBase = '/') {
  if (callbackUri) {
    const domainStartsAt = '://'
    const hostAndPath = callbackUri.substring(
      callbackUri.indexOf(domainStartsAt) + domainStartsAt.length,
    )
    return hostAndPath
      .substring(hostAndPath.indexOf(routeBase) + routeBase.length - 1)
      .replace(/\/$/, '')
  }
  return null
}

export function tokenExp(token?: string | null) {
  if (token) {
    const parsed = parseJwt(token)
    return parsed.exp ? parsed.exp * 1000 : null
  }
  return null
}

export function firstLetterUppercase(str: string) {
  return str && str.length > 0
    ? str.charAt(0).toUpperCase() + str.slice(1)
    : ''
}

export function isTokenExpired(token?: string | null) {
  const tokenExpiryTime = tokenExp(token)
  if (tokenExpiryTime)
    return tokenExpiryTime < new Date().getTime()

  return false
}
