<script setup lang="ts">
import { Button, Card } from '@wartek-id/belajar-id-components'
import { toRefs } from 'vue'

const props = defineProps({
  urlImage: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: 'Masih bingung cara menggunakan Portal Admin?',
  },
  description: {
    type: String,
    default:
      'Cek panduan berikut, untuk cara penggunaan portal admin belajar.id',
  },
  btnText: {
    type: String,
    default: 'Baca selengkapnya',
  },
  path: {
    type: String,
    default: '',
  },
})
const { urlImage, title, description, path } = toRefs(props)
</script>

<template>
  <Card class="w-full sm:w-1/3 ma-1 border border-high p-5 px-8">
    <div v-if="urlImage" class="flex items-center sm:w-full">
      <img :src="urlImage" class="w-20" alt="image-magnifying">
    </div>
    <div class="mt-4">
      <p class="font-semibold my-4">
        {{ title }}
      </p>
      <p class="text-subdued font-normal mb-4">
        {{ description }}
      </p>
      <Button :href="path" variant="quiet">
        {{ btnText }}
      </Button>
    </div>
  </Card>
</template>
