import axios from 'axios'
import qs from 'qs'

export interface Province { id: string; name: string }
export interface ResponseMeta { limit: number; page: number; total: number }
export interface GetCityParams {
  province_id: string
  dinas_area_id?: string
}
export const citiesInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {},
  paramsSerializer: (params) => {
    return qs.stringify(params)
  },
})

export const useGetCities = () => {
  const getCities = async (params: any) => {
    const res = await citiesInstance.get<{ data: Province[]; meta: ResponseMeta }>(
      'belajar-id/account-lookup/v1/lookup/city', {
        params,
      },
    )
    return res.data.data.map(item => ({
      value: item.id,
      text: item.name,
    }))
  }

  return { getCities }
}
