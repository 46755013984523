import { defineStore } from 'pinia'
import { UserService } from '@/services'

export interface RelationTuple {
  namespace: string
  object: string
  relation: string
}

export interface AclStoreState {
  relationTuples: RelationTuple[]
}

function createStoreModule() {
  const service = new UserService()

  return defineStore('acl', {
    state(): AclStoreState {
      return {
        relationTuples: [],
      }
    },
    getters: {
      roles(state) {
        return state.relationTuples.filter((v: any) => v.namespace === 'roles')
      },
      permissions(state) {
        return state.relationTuples
          .filter((v: any) => v.namespace === 'permissions')
          .map((p: any) => `${p.object}_${p.relation}`) || []
      },
      enabledNewColumns() {
        const toggleShowNewColumn = import.meta.env.VITE_TOGGLE_NEW_COLUMN
        return toggleShowNewColumn.split(' ')
      },
    },
    actions: {
      async getRelationTuples() {
        const data = await service.getRelationTuples()
        this.relationTuples = data.data.relation_tuples

        return data
      },
      hasPermissions(permissions?: string[]) {
        if (!permissions)
          return true

        const permissionsOwned = this.permissions
        if (permissionsOwned !== null && permissions.length > 0)
          return permissionsOwned.some((item: any) => permissions.includes(item))
        else
          return false
      },
      isNewColumnEnabled(value: string) {
        return this.enabledNewColumns.includes(value)
      },
    },
  })
}

export const useAclStore = createStoreModule()
