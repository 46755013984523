import { setLocale } from 'yup'

// set yup locale
setLocale({
  mixed: {
    required(params) {
      return `${params.path} wajib diisi`
    },
  },
})
