import { createI18n } from 'vue-i18n'
import id from '../locales/id.json'
import type { UserPlugin } from '~/types'

export const i18n = createI18n({
  locale: 'id',
  messages: { id },
  legacy: false,
})

export const install: UserPlugin = ({ app }) => {
  app.use(i18n)
}
