<script setup lang="ts">
import { computed, onMounted, ref, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import { Button, ListItem } from '@wartek-id/belajar-id-components'
import { ellipsis, tooltipText } from '~/utils'

const props = defineProps<{
  items?: Array<Record<string, any>>
}>()

const emit = defineEmits<{
  (e: 'click:navigate', payload: Record<string, any>): void
}>()

const list = ref()
const height = ref('')
const route = useRoute()

const { items } = toRefs(props)

const isOpen = computed<boolean>(() => height.value !== '')

const toggle = () => {
  if (!isOpen.value)
    height.value = `${list.value.scrollHeight}px`

  else height.value = ''
}

const parentRoute = computed(() => `/${route.path.split('/')[1]}`)

const isDropdownActive = computed(() => {
  return items?.value?.find((c: any) => {
    const menu = c.to?.toString().split('/') || []
    if (menu.length === 3)
      return (`/${menu[1]}`) === parentRoute.value

    return c.to?.toString() === parentRoute.value
  })
})

const isActive = (item: any) => {
  const menuItem = item.to?.toString().split('/') || []

  if (menuItem.length === 3)
    return (`/${menuItem[1]}`) === parentRoute.value

  return item.to === parentRoute.value
}

onMounted(() => {
  setTimeout(() => {
    if (isDropdownActive.value)
      toggle()
  }, 100)
})
</script>

<template>
  <div class="treeview">
    <slot name="action-button" :is-open="isOpen" :toggle="toggle" :is-active="isDropdownActive">
      <Button id="trigger" variant="primary" @click="toggle">
        Toggle
      </Button>
    </slot>

    <div ref="list" class="treeview-list" :style="`height:${height}`">
      <template v-for="(item, index) in items" :key="index">
        <ListItem
          :ref="`subitem${index}`"
          v-tooltip.right="tooltipText(item.text)"
          class="transition duration-200 !px-4 mb-1"
          :class="{ active: isActive(item) }"
          content-class="text-left text-sm"
          :to="item.to"
          @click="emit('click:navigate', item)"
        >
          <span :title="item.text">
            {{ ellipsis(item.text) }}
          </span>
        </ListItem>
      </template>
    </div>
  </div>
</template>

<style scoped>
.treeview > .treeview-list {
height: 0;
overflow: hidden;
transition: height 0.25s ease-out;
}

.active{
@apply bg-primary bg-opacity-70 hover:!bg-primary hover:!bg-opacity-70 text-white font-semibold
}
</style>
