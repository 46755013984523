import { ref, watch } from 'vue'
import { BaseCrudService } from '~/services'
import { useCrud } from '~/composables'

export interface AccountPd {
  id: string
  provinsi: string
  kota_kab: string
  tipe_akun: string
  tipe_sekolah: string
  jenjang: string
  nama_sekolah: string
  updated_at: string
}

export interface AccountPdParams {
  path: string
}

export class AccountPdService extends BaseCrudService<AccountPd> {
  protected path = ''

  constructor(params: AccountPdParams) {
    super()
    this.path = params.path
  }
}

export const lastUpdateAccount = ref('')

export const useAccountPd = (params: AccountPdParams) => {
  const { items, ...rest } = useCrud<AccountPd>(new AccountPdService(params))

  watch(items, (val) => {
    lastUpdateAccount.value = val[0]?.updated_at
  })

  return {
    items,
    ...rest,
  }
}
