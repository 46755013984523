import axios from 'axios'
import type { ResponseWithMeta, UploadFileResponse } from '~/types'

export const fileUploadInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: false,
  headers: {},
})

export const useFileUpload = () => {
  const uploadPublic = (file: File, category: string) => {
    const body = new FormData()
    body.append('file', file)
    body.append('category', category)
    return fileUploadInstance.post<ResponseWithMeta<UploadFileResponse>>('/belajar-id/file/upload/public', body)
  }

  const uploadPrivate = (file: File, category: string) => {
    const body = new FormData()
    body.append('file', file)
    body.append('category', category)
    return fileUploadInstance.post<ResponseWithMeta<UploadFileResponse>>('/belajar-id/file/upload/private', body)
  }

  return { uploadPublic, uploadPrivate }
}
