import { capitalCase } from 'change-case'
import dayjs from 'dayjs'
import type { PaginationMeta, RawPaginationMeta } from '~/types'

export const mapMeta = (meta: RawPaginationMeta): PaginationMeta => {
  return {
    total: meta.total,
    perPage: meta.per_page,
    currentPage: meta.current_page,
    lastPage: meta.last_page,
    firstPage: meta.first_page,
    firstPageUrl: meta.first_page_url,
    lastPageUrl: meta.last_page_url,
    nextPageUrl: meta.next_page_url,
    previousPageUrl: meta.previous_page_url,
  }
}

export const localDate = (str: string, locale = 'id') => {
  return dayjs(str).locale(locale).format('DD/MM/YYYY HH:mm')
}

export const localDateTime = (str: string, locale = 'id') => {
  return dayjs(str).locale(locale).format('YYYY-MM-DD HH.mm')
}

export const normalizeDate = (str: string, locale = 'id') => {
  const date = str?.replace('T', ' ').replace('.000+00:00', '')
  return dayjs(date).locale(locale).toDate()
}

export const fileToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

export const assetURL = (path: string) => {
  return `${import.meta.env.VITE_STORAGE_URL}/${path}`
}

export const cloudUrl = (path: string) => {
  return import.meta.env.VITE_STORAGE_URL + path
}

export const number = (num: string | number) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const titleCase = (input: string) => {
  return capitalCase(input)
}

export const safeParseJson = (json: string, defaultValue: any = []) => {
  try {
    return JSON.parse(json || '')
  }
  catch (e) {
    return defaultValue
  }
}

export const DEFAULT_MAX_TITLE_LENGTH = 30

export const ellipsis = (text: string, length = DEFAULT_MAX_TITLE_LENGTH) => {
  if (text.length > length)
    return `${text.substring(0, length)}...`

  return text
}

export const tooltipText = (text: string) =>
  text.length > DEFAULT_MAX_TITLE_LENGTH ? text : null

export const sortArrayByText = (array: Array<any>, propertyName: string) => {
  return array.sort((a, b) => {
    const fa = a[propertyName].toLowerCase()
    const fb = b[propertyName].toLowerCase()

    if (fa < fb)
      return -1

    if (fa > fb)
      return 1

    return 0
  })
}
