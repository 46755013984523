import type { Pinia } from 'pinia'
import { createPinia } from 'pinia'

let piniaInstance: Pinia | null = null

const pinia = (): Pinia => {
  if (piniaInstance !== null)
    return piniaInstance

  piniaInstance = createPinia()

  return piniaInstance
}

export { useAclStore } from './acl'
export { useOidcStore } from './oidc'
export default pinia
