import { floatingPlugin } from '@wartek-id/belajar-id-components'
import { defineAsyncComponent } from 'vue'
import type { UserPlugin } from '~/types'

const DataTable = defineAsyncComponent(
  () =>
    import(
      '@wartek-id/belajar-id-components/src/components/DataTable/DataTable.vue'
    ),
)

export const install: UserPlugin = ({ app }) => {
  app.use(floatingPlugin as any)

  app.component('DataTable', DataTable)
}
