import type { Module } from 'vuex'
import type { AppRootState } from '..'

export interface LayoutState {
  mini: boolean
}

export const layoutModule = <Module<LayoutState, AppRootState>>{
  namespaced: true,
  state: () => ({
    mini: false,
  }),
  mutations: {
    setMini(state, val: boolean) {
      state.mini = val
    },
    toggleMini(state) {
      state.mini = !state.mini
    },
  },
  getters: {
    mini: s => s.mini,
  },
}
