import axios from 'axios'
import qs from 'qs'

import { useOidcStore } from '~/pinia'
import router from '~/router'
import type { UserPlugin } from '~/types'

axios.defaults.baseURL = String(import.meta.env.VITE_API_BASE_URL)

axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params)
}
axios.defaults.withCredentials = true

export const install: UserPlugin = () => {
  if (import.meta.env.DEV) {
    axios.defaults.headers.common.Authorization
      = 'Bearer B3laJ4R!d-Sk1p@utHT0k3N'
  }

  axios.interceptors.request.use(
    (config) => {
      const oidcStore = useOidcStore()
      if (oidcStore.oidcIsAuthenticated) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${oidcStore.oidcAccessToken}`,
        }
      }
      return config
    },
    error => Promise.reject(error),
  )

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response.status === 403)
        router.push('/unauthorized')

      return Promise.reject(error)
    },
  )
}
