import axios from 'axios'
import type { CrudInterface, ResponseWithMeta } from '~/types'

export type GenericPayload = Record<string, any>
export type CreatePayload = Record<string, any>
export type UpdatePayload = Record<string, any>
export interface CrudOptions {
  generic: GenericPayload
  create: CreatePayload
  update: UpdatePayload
}

export abstract class BaseCrudService<E> implements CrudInterface {
  protected path = ''

  async all<T = GenericPayload>(params: T) {
    return axios.get<ResponseWithMeta<E[]>>(this.path, {
      params,
      withCredentials: true,
    })
  }

  async create<T = CreatePayload>(payload: T) {
    return axios.post(this.path, payload)
  }

  async find<T = string>(id: T) {
    return axios.get<E>(`${this.path}/${id}`)
  }

  async update<T = string, U = UpdatePayload>(id: T, payload: U) {
    return axios.put(`${this.path}/${id}`, payload)
  }

  async delete<T = string, R = any>(id: T) {
    return axios.delete<R>(`${this.path}/${id}`)
  }
}
