import { createApp } from 'vue'
import 'nprogress/nprogress.css'
import '@gits-id/quill-editor/dist/style.css'

import App from '~/App.vue'
import router from '~/router'
import '~/assets/css/main.css'
import pinia from '~/pinia'
import store from '~/store'

const app = createApp(App)

// global plugins
app.use(router)
app.use(pinia())
app.use(store)

// register all user plugins
Object.values(import.meta.glob('./plugins/*.ts', { eager: true })).forEach(
  (i: any) => {
    i.install?.({ app, router, store })
  },
)

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $screens: (screen: any) => boolean
  }
}

// mount the element
app.mount('#app')
