import {
  createAuth,
  authModule as createAuthModule,
  registerAxiosInterceptors,
} from '@gravitano/vue-auth'
import axios from 'axios'
import type { AppRootState } from '~/store'
import store from '~/store'
import router from '~/router'
import type { UserPlugin } from '~/types'
import { authOptions } from '~/authOptions'

export const useAuth = () =>
  createAuth<AppRootState>(authOptions, store, router as any, axios as any)

export const authModule = createAuthModule(authOptions)

export const install: UserPlugin = ({
  store: storePlugin,
  router: routerPlugin,
}) => {
  registerAxiosInterceptors(
    axios as any,
    authOptions,
    storePlugin,
    routerPlugin as any,
  )
}
