<script setup lang="ts">
import { Button, Card, Icon } from '@wartek-id/belajar-id-components'
import { computed } from 'vue'
import { useOidcStore } from '~/pinia'

const { user } = useOidcStore()

const title = computed(() => {
  return `Selamat Datang ${user?.profile?.name}`
})
</script>

<template>
  <page-header :title="title" />
  <div class="mt-4">
    <Card class="border border-high p-4 py-14" shadow>
      <div class="flex flex-col sm:flex-row gap-14 justify-center">
        <div class="grid grid-cols-1 gap-8 md:w-7/12">
          <span class="uppercase md:text-sm text-xs font-semibold">Selamat datang di Portal Admin Akun belajar.id</span>
          <h1 class="md:text-4xl text-lg font-semibold">
            Periksa, kelola, dan bagikan Akun belajar.id di sekolah Anda dengan
            lebih mudah.
          </h1>
          <p class="text-subdued md:text-lg text-sm font-normal">
            Anda dapat mengakses fitur-fitur penting portal admin melalui tombol
            dibawah atau menu pada bagian kiri laman ini.
          </p>
          <div class="flex flex-wrap md:flex-nowrap gap-4">
            <Button class="md:mb-2" to="/profile">
              Profil Pengguna
            </Button>
            <Button to="/account-management">
              Data Akun belajar.id
            </Button>
          </div>
        </div>
        <div class="hidden sm:flex items-center">
          <img src="/typing.svg?url" alt="image-typing">
        </div>
      </div>
    </Card>
  </div>

  <div class="flex flex-wrap md:flex-nowrap gap-4 mt-4">
    <CardInformation
      url-image="/magnifying.svg?url"
      title="Masih bingung cara menggunakan Portal Admin?"
      description="Cek panduan berikut, untuk cara penggunaan portal admin belajar.id"
      path="https://pusatinformasi.belajar.id/hc/en-us/sections/11338391714841-Portal-Admin"
    />
    <CardInformation
      url-image="/question.svg?url"
      title="Untuk Informasi lebih lanjut seputar Akun belajar.id"
      description="Baca lebih lanjut tentang Akun belajar.id dan manfaatnya"
      path="https://pusatinformasi.belajar.id/"
    />
    <Card
      class="flex flex-col sm:flex-row gap-2 p-2 text-gray-100 sm:w-1/3 h-fit border-surface-informational-default bg-surface-informational-subdued"
    >
      <div class="mt-1">
        <Icon icon="ic:outline-info" />
      </div>
      <div>
        <p class="mb-3">
          Untuk bantuan lebih lanjut, silahkan hubungi tim helpdesk Akun
          Belajar.id
        </p>
        <p>Jam Operasional;</p>
        <p>Senin - Jumat</p>
        <p>09:00 - 18:00</p>
        <Button
          href="https://pusatinformasi.belajar.id/hc/en-us/articles/5524023126553-Cara-Melaporkan-Kendala-Melalui-Tombol-Butuh-Bantuan-"
          class="mt-3"
          variant="ultimate"
        >
          <Icon icon="bi:question-circle" />Butuh Bantuan
        </Button>
      </div>
    </Card>
  </div>
</template>

<route lang="yaml">
meta:
  auth: true
  title: Beranda
</route>
