import type { RouteLocationNormalized } from 'vue-router'

import { requiresAuthMiddleware } from './auth'
import { guestMiddleware } from './guest'
import { authOptions } from '~/authOptions'

export const handleMiddleware = async (
  oidcStore: any,
  aclStore: any,
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
) => {
  try {
    await oidcStore.loadUser()
  }
  catch {}

  const middlewareParams = {
    oidcStore,
    aclStore,
    to,
    from,
    options: authOptions,
  }

  switch (to.meta.auth) {
    case 'guest':
      return guestMiddleware(middlewareParams)
    case true:
      return requiresAuthMiddleware(middlewareParams)
    default:
      return true
  }
}
