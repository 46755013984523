import { defineStore } from 'pinia'

export const useLayout = defineStore('layout', {
  state: () => ({
    mini: false,
    fixed: false,
    navDrawerOpen: true,
    isMobile: false,
  }),
  getters: {
    getMini: s => s.mini,
  },
  actions: {
    setMini(val: boolean) {
      this.mini = val
    },
    toggleMini() {
      this.mini = !this.mini
    },
    setNavDrawerOpen(payload: boolean) {
      this.navDrawerOpen = payload
    },
    setIsMobile(payload: boolean) {
      this.isMobile = payload
    },
  },
})
