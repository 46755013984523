import { ref } from 'vue'
import type { RouteLocation } from 'vue-router'
import { useAclStore } from '~/pinia'

export interface Menu extends Record<string, any> {
  text: string
  to?: string | RouteLocation
  children?: Menu[]
  permission?: string[]
}

export const menus = ref<Menu[]>([
  {
    text: 'Beranda',
    to: '/',
  },
  {
    text: 'Dashboard Operasional',
    children: [
      {
        text: 'Dashboard Operasional',
        to: '/dashboard-operasional/ptk',
        permission: ['dashboard_operational_read'],
      },
      {
        text: 'Regional Activation Summary',
        to: '/regional-activation-summary',
        permission: ['dashboard_regional_activation_read'],
      },
    ],
  },
  {
    text: 'Manajemen Akun',
    children: [
      {
        text: 'Data Akun belajar.id',
        to: '/account-management',
        permission: ['account_management_read'],
      },
    ],
  },
  {
    text: 'Pengaturan Admin',
    children: [
      {
        text: 'Manajemen Akses',
        to: '/access-management',
        permission: ['access_management_update'],
      },
      {
        text: 'Pengajuan Akun',
        to: '/account-request',
        permission: ['account_request_create'],
      },
      {
        text: 'Manajemen Kuota Domain',
        to: '/domain-quota-management',
        permission: ['domain_quota_management_read'],
      },
    ],
  },
  {
    text: 'Data Operasional',
    children: [
      {
        text: 'Data Perangkat Chromebook',
        to: '/chromebook',
        permission: ['chromebook_device_read'],
      },
      {
        text: 'Chromebook Analytics',
        to: '/chromebook-analytics',
        permission: ['chromebook_analytics_read'],
      },
      {
        text: 'Unggah Data Perangkat',
        to: '/upload-device-data',
        permission: ['chromeos_device_uploaded_create'],
      },
      {
        text: 'Auto Sync',
        to: '/auto-sync',
        permission: ['autosync_status_read'],
      },
    ],
  },
  {
    text: 'Paparan Data',
    children: [
      {
        text: 'Aktivasi Akun belajar.id Daerah',
        to: '/dashboard-activation-account',
        permission: ['dashboard_activation_account_read'],
      },
      {
        text: 'Panduan Penggunaan Dashboard',
        to: '/onboarding-activation-account',
        permission: ['onboarding_activation_account_read'],
      },
    ],
  },
  {
    text: 'User Management',
    children: [
      // {
      //   text: 'Users',
      //   to: '/users',
      // },
      {
        text: 'Reset Password',
        to: '/reset-password',
        permission: ['reset_password_update'],
      },
    ],
  },
  {
    text: 'Content',
    children: [
      {
        text: 'Pengumuman',
        to: '/announcement-bar',
        permission: ['announcement_bar_read'],
      },
      {
        text: 'Article',
        to: '/articles',
        permission: ['article_read'],
      },
      {
        text: 'Products',
        to: '/products',
        permission: ['product_read'],
      },
      {
        text: 'Webinar',
        to: '/webinar',
        permission: ['webinar_schedule_read'],
      },
      {
        text: 'Painpoints',
        to: '/painpoints',
        permission: ['pain_point_read'],
      },
      {
        text: 'FAQ',
        to: '/faq',
        permission: ['faq_read'],
      },
      {
        text: 'Third Platform',
        to: '/third-platform',
        permission: ['thirdparty_platform_read'],
      },
      {
        text: 'Government Statements',
        to: '/government-statements',
        permission: ['government_statement_read'],
      },
    ],
  },
  // {
  //   text: 'Work Order',
  //   children: [
  //     {
  //       text: 'Work Order Error',
  //       to: '/work-order-error',
  //     },
  //     {
  //       text: 'Adhoc Auto Sync',
  //       to: '/adhoc-auto-sync',
  //     },
  //   ],
  // },
  {
    text: 'Refferal',
    children: [
      {
        text: 'Referral Code List',
        to: '/referral-code',
        permission: ['referral_code_read'],
      },
      {
        text: 'User Referral Code',
        to: '/user-referral-code',
        permission: ['user_referral_code_read'],
      },
    ],
  },
  // {
  //   text: 'Pengaturan Content',
  //   to: '/content',
  // },
  // {
  //   text: 'Paparan Data',
  //   to: '',
  // },
  // {
  //   text: 'Pengaturan Admin',
  //   to: '',
  // },
  {
    text: 'Workflows',
    to: '/workflows',
    permission: ['workflow_read'],
  },
])

export const filterMenu = () => {
  const aclStore = useAclStore()

  return menus.value
    .map((item) => {
      const children = item.children?.filter((child: any) =>
        aclStore.hasPermissions(child.permission),
      )

      return { ...item, children }
    })
    ?.filter(
      child =>
        aclStore.hasPermissions(child.permission)
        && (child.children ? child.children?.length > 0 : true),
    )
}
