import type { Module } from 'vuex'
import type { AppRootState } from '..'

export interface QuotaDomainState {
  isDeletionOnProcess: boolean
}

export const quotaDomainModule = <Module<QuotaDomainState, AppRootState>>{
  namespaced: true,
  state: () => ({
    isDeletionOnProcess: false,
  }),
  mutations: {
    setIsDeletionOnProcess(state, val: boolean) {
      state.isDeletionOnProcess = val
    },
  },
}
