import type { Module } from 'vuex'
import type { AppRootState } from '..'

export interface ChromebookState {
  page: number
}

export const chromebookModule = {
  namespaced: true,
  state: () => ({
    page: 1,
  }),
  mutations: {
    setPage(state, val: number) {
      state.page = val
    },
  },
} as Module<ChromebookState, AppRootState>
