import { reactive, ref } from 'vue'
import { debouncedWatch, get } from '@vueuse/core'
import { mapMeta } from '~/utils'
import type { CrudInterface, GenericPayload, PaginationMeta } from '~/types'

export const useCrud = <E, P = GenericPayload>(service: CrudInterface) => {
  const loading = reactive({
    items: false,
    detail: false,
    delete: false,
    create: false,
    update: false,
  })
  const search = ref('')
  const items = ref<E[]>([])
  const item = ref<E | null>(null)
  const meta = ref<PaginationMeta>({
    total: 0,
    perPage: 10,
    currentPage: 1,
    lastPage: 1,
    firstPage: 1,
    firstPageUrl: '',
    lastPageUrl: '',
    nextPageUrl: '',
    previousPageUrl: '',
  })

  const fetchItems = async <T = Record<string, any>>(params?: T) => {
    loading.items = true
    try {
      const res = await service.all(params)

      items.value = res.data.data
      meta.value = mapMeta(res.data.meta)

      return res.data
    }
    catch (e: any) {
      return e.response?.data
    }
    finally {
      loading.items = false
    }
  }

  const deleteItem = async (id: string) => {
    loading.delete = true
    try {
      const res = await service.delete(id)
      loading.delete = false

      return res.data
    }
    catch (e: any) {
      return e.response?.data
    }
    finally {
      loading.delete = false
    }
  }

  const create = async (payload: P) => {
    try {
      loading.create = true
      const res = await service.create(payload)
      loading.create = false

      return res.data
    }
    catch (e: any) {
      return e.response?.data
    }
    finally {
      loading.create = false
    }
  }

  const fetchItem = async <T = any>(id: string, path = 'data'): Promise<T> => {
    try {
      loading.detail = true
      const res = await service.find(id)
      loading.detail = false

      item.value = get(res.data, path)

      return res.data
    }
    catch (e: any) {
      return e.response?.data
    }
    finally {
      loading.detail = false
    }
  }

  const update = async (id: string, payload: P) => {
    try {
      loading.update = true
      const res = await service.update(id, payload)
      loading.update = false

      return res.data
    }
    catch (e: any) {
      return e.response?.data
    }
    finally {
      loading.update = false
    }
  }

  const watchSearch = (cb?: (val: string) => void) =>
    debouncedWatch(
      search,
      (val) => {
        if (cb) {
          cb(val)
        }
        else {
          fetchItems({
            search: val,
          })
        }
      },
      { debounce: 500 },
    )

  return {
    search,
    loading,
    items,
    item,
    meta,
    fetchItems,
    deleteItem,
    create,
    fetchItem,
    update,
    watchSearch,
  }
}
