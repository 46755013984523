<script setup lang="ts">
import { computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'

// Supports weights 100-900
import '@fontsource-variable/inter'
import '@fontsource-variable/inter/wght-italic.css'

const { t } = useI18n()

const siteData = reactive({
  title: t('site.title'),
  description: t('site.description'),
})

useHead({
  // Can be static or computed
  title: computed(() => siteData.title),
  meta: [
    {
      name: 'description',
      content: computed(() => siteData.description),
    },
  ],
})
</script>

<template>
  <router-view />
</template>
