import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' // depen
import 'dayjs/locale/id'
import type { UserPlugin } from '~/types'

dayjs.locale('id')
dayjs.extend(utc)
dayjs.extend(timezone)

export const install: UserPlugin = ({ app }) => {
  app.config.globalProperties.$dayjs = dayjs
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $dayjs: typeof dayjs
  }
}
