import { createStore } from 'vuex'
import { authModule, chromebookModule, layoutModule, quotaDomainModule } from './modules'

export interface AppRootState {
  [x: string]: any
}

const store = createStore<AppRootState>({
  modules: {
    auth: authModule,
    layout: layoutModule,
    chromebook: chromebookModule,
    quotaDomain: quotaDomainModule,
  },
})

export default store
