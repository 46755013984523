import type { MiddlewareParams } from '~/middleware'

export const requiresAuthMiddleware = async ({
  oidcStore,
  aclStore,
  to,
  options,
}: MiddlewareParams) => {
  if (import.meta.env.DEV)
    return true

  const hasAccess = await oidcStore.checkAccess(to)
  if (!hasAccess) {
    return options.redirect.login
  }
  else {
    const rtResponse = await aclStore.getRelationTuples()
    if (rtResponse.status === 200) {
      if (aclStore.hasPermissions(to.meta.permission as string[]))
        return true
      else
        return '/unauthorized'
    }

    return options.redirect.login
  }
}
